import React, { useEffect, useState } from 'react'
import "./Profile.css"
import lakshyaPhoto from "../../img/Lakshya_Photo.png"
import { TypeAnimation } from 'react-type-animation';
import Resume from "./Lakshya_Resume.pdf";

const Profile = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTheme = () => {
        if (toggleState === 0) {
            setToggleState(1);
            document.body.classList.add('dark-theme');
        }
        else {
            setToggleState(0);
            document.body.classList.remove('dark-theme');
        }
    }

    const [currentText, setCurrentText] = useState('');


    return (
        <header className='profile container'>
            {/* Dark Theme */}
            <i class={toggleState ? "uil uil-sun change-theme" : "uil uil-moon change-theme"} id='theme-button' onClick={toggleTheme}></i>

            <div className="profile-container grid">
                <div className="profile-data">

                    <div className="profile-border">
                        <div className="profile-prefil">
                            <img src={lakshyaPhoto} alt="" />
                        </div>
                    </div>

                    <h2 className="profile-name">Lakshya Soni</h2>
                    <h3 className='profile-profession'>Full Stack Developer</h3>

                    <ul className="profile-social">
                        <a href="https://www.instagram.com/lakshya_s018/" target="_blank" className="profile-social-link">
                            <i class="uil uil-instagram"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/lakshya-soni-0ba345194/" target="_blank" className="profile-social-link">
                            <i class="uil uil-linkedin-alt"></i>
                        </a>
                        <a href="https://github.com/lakshya018" target="_blank" className="profile-social-link">
                            <i class="uil uil-github-alt"></i>
                        </a>
                    </ul>
                </div>

                <div className='profile-information'>
                    <div className="profile-info grid">
                        <p className="profile-info-about">
                            Hi, I am Lakshya Soni, a <TypeAnimation
                                sequence={[
                                    // Same String at the start will only be typed once, initially
                                    'Full Stack Developer',
                                    1000,
                                    'Web Designer',
                                    1000,
                                    'Graphic Designer',
                                    1000,
                                    'Video Editor',
                                    1000,
                                ]}
                                speed={50}

                                repeat={Infinity}

                            ></TypeAnimation> <br />
                            B.Tech in Electrical Engineering from IIT Roorkee. With a passion for web design and full stack development, I have gained valuable experience in these fields.
                        </p>

                    </div>


                    <div className="profile-buttons">
                        <a download="Lakshya_Soni_Resume.pdf" href={Resume} className="button">
                            Download CV <i class="uil uil-file-download"></i>
                        </a>

                        <div className="profile-buttons-small">
                            <a href="https://wa.me/918824656818" className="button button-small button-gray">
                                <i class="uil uil-whatsapp"></i>
                            </a>
                            <a href="mailto:iitrlakshya@gmail.com" className="button button-small button-gray">
                                <i class="uil uil-envelope-alt"></i>
                            </a>
                        </div>

                    </div>
                </div>

            </div>

        </header>
    )
}

export default Profile;