import './App.css';
import Profile from './Components/Profile/Profile';
import Main from './Components/Main/Main';
import Footer from './Components/Footer/Footer';


function App() {
  
  return (
    <div className="App">
  
      <Profile/>
      <Main/>
      <Footer/>
      
    </div>
  );
}

export default App;
