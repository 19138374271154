import React from 'react'
import "./Footer.css"

const Footer = () => {
  return (
    <footer className='footer container'>
        <span className='footer-copy'>
            &#169; Lakshya Soni. All Rights Reserved.
        </span>
    </footer>
  )
}

export default Footer