import React, { useRef, useState } from 'react'
import "./Main.css"
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import Project1 from "../../img/note-taking.png"
import Project2 from "../../img/movieapp.jpg"
import Project3 from "../../img/eess.jpg"
import Project4 from "../../img/adobe.png"

const Main = () => {

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <main className='main'>
            <section className="filters container">

                {/*========= Filter Tabs ========== */}
                <ul className='filters-content'>
                    <button className={toggleState === 1 ? "filters-btn filter-btn-active" : "filters-btn"} onClick={() => toggleTab(1)}>
                        Projects
                    </button>
                    <button className={toggleState === 2 ? "filters-btn filter-btn-active" : "filters-btn"} onClick={() => toggleTab(2)}>
                        Skills
                    </button>
                </ul>

                <div className="filters-sections">
                    <div className={toggleState === 1 ? "projects-content grid filter-active" : "projects-content grid"} id='projects' >
                     
                            <Swiper
                                cssMode={true}
                                navigation={true}
                                pagination={{
                                    "clickable" : true,
                                }}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                                
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="portfolio-content">

                                        <img src={Project1} alt="" className='portfolio-img' />

                                        <div className="portfolio-data">
                                            <h3 className='portfolio-title'>iNotes Application</h3>
                                            <p className="portfolio-description">The Full Stack Note-Making Web App allows users to create, delete, and edit notes, offering a seamless and user-friendly interface for efficient note organization.</p>
                                            <a href='https://note-zia.netlify.app/' target='_blank' className="portfolio-btn">Demo <i className="uil uil-arrow-right btn-icon"></i> </a>
                                        </div>


                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="portfolio-content">
                                        <img src={Project2} alt="" className='portfolio-img' />

                                        <div className="portfolio-data">
                                            <h3 className='portfolio-title'>Movie Application</h3>
                                            <p className="portfolio-description">The Online Movie/Series Watching App enables users to watch and download movies/series, providing a convenient platform for streaming and offline viewing.</p>
                                            <a href='https://movieapp018.netlify.app/' target='_blank' className="portfolio-btn">Demo <i className="uil uil-arrow-right btn-icon"></i> </a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="portfolio-content">
                                        <img src={Project3} alt="" className='portfolio-img' />

                                        <div className="portfolio-data">
                                            <h3 className='portfolio-title'>EESS Website</h3>
                                            <p className="portfolio-description">The EESS website connects electrical engineering students at IIT Roorkee with faculty and alumni, offering a platform for communication, updates, and sharing department-related notices.</p>
                                            <a href='https://eessiitr.netlify.app/' target='_blank' className="portfolio-btn">Demo <i className="uil uil-arrow-right btn-icon"></i> </a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="portfolio-content">
                                        <img src={Project4} alt="" className='portfolio-img' />

                                        <div className="portfolio-data">
                                            <h3 className='portfolio-title'>Desgining Works</h3>
                                            <p className="portfolio-description">Here are some of my works in graphic designing and web designing. I bring ideas to life through captivating designs. Let's explore together!</p>
                                            <a href="https://www.instagram.com/illustrator_ls/" target="_blank" className="portfolio-btn">Demo <i className="uil uil-arrow-right btn-icon"></i> </a>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
            

                    </div>

                    <div className={toggleState === 2 ? "skills-content grid filter-active" : "skills-content grid"} id='skills'>

                        <div className='skills-body'>
                            <div className="skill-content">
                                <h3 className='skill-title'><i class="uil uil-brackets-curly skill-icon"></i> Front-End Development</h3>

                                <div className="skill-body">
                                    <div className="skill-column-1">
                                        <div className="skill">
                                            <h4 className="skill-name">HTML</h4>
                                            <span className='skill-experience'>Expert</span>
                                        </div>
                                        <div className="skill">
                                            <h4 className="skill-name">CSS</h4>
                                            <span className='skill-experience'>Expert</span>
                                        </div>
                                        <div className="skill">
                                            <h4 className="skill-name">JavaScript</h4>
                                            <span className='skill-experience'>Expert</span>
                                        </div>
                                    </div>
                                    <div className="skill-column-2">
                                        <div className="skill">
                                            <h4 className="skill-name">ReactJS</h4>
                                            <span className='skill-experience'>Intermediate</span>
                                        </div>
                                        <div className="skill">
                                            <h4 className="skill-name">BootStrap</h4>
                                            <span className='skill-experience'>Expert</span>
                                        </div>
                                        <div className="skill">
                                            <h4 className="skill-name">Git</h4>
                                            <span className='skill-experience'>Beginner</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="skill-content">
                                <h3 className='skill-title'><i class="uil uil-swatchbook skill-icon"></i> Designing </h3>

                                <div className="skill-body ps-5 ">
                                    <div className="skill-column-1">
                                        <div className="skill">
                                            <h4 className="skill-name">Adobe Illustrator</h4>
                                            <span className='skill-experience'>Intermediate</span>
                                        </div>
                                        <div className="skill">
                                            <h4 className="skill-name">Adobe Photoshop</h4>
                                            <span className='skill-experience'>Beginner</span>
                                        </div>
                                        <div className="skill">
                                            <h4 className="skill-name">Figma</h4>
                                            <span className='skill-experience'>Intermediate</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="skill-content">
                                <h3 className='skill-title'><i class="uil uil-server-network skill-icon"></i> Back-End Development</h3>

                                <div className="skill-body ps-5 ">
                                    <div className="skill-column-1">
                                        <div className="skill">
                                            <h4 className="skill-name">NodeJS</h4>
                                            <span className='skill-experience'>Beginner</span>
                                        </div>
                                        <div className="skill">
                                            <h4 className="skill-name">MongoDB</h4>
                                            <span className='skill-experience'>Beginner</span>
                                        </div>
                                        <div className="skill">
                                            <h4 className="skill-name">ExpressJS</h4>
                                            <span className='skill-experience'>Beginner</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </section>
        </main>
    )
}

export default Main